.signing-links {
    .second-btn {
        border: 1px solid #6658dd;
        color: #6658dd;
        background-color: #ffffff;
        margin-left: 5px;
    }
    .second-btn:hover {
        border: 1px solid #574bbc;
        color: #574bbc;
        background-color: #f6f6f6;
    }
}
