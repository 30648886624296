.profile-products {

    .buttons {
        direction: rtl;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        margin-bottom: 20px;

        .double-item {
            .signing-links {
                .second-btn {
                    margin-left: -2px;
                }
            }
        }
        .grouped-btn.delete {
            margin-left: -2px;
            display: hidden;
        }
    }


    .header-menu-table {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .menu-header {
            width: 10%;
        }

        .menu-item {
            max-width: 20%;
        }

        .menu-item.double-item {
            max-width: 55%;

            .signing-links {
                .second-btn {
                    margin-left: -2px;
                }
            }

            .grouped-btn.delete {
                position: absolute;
                z-index: 10;
                margin-left: -2px;
            }

            @media (max-width: 1300px) {
                max-width: 18%;

                .grouped-btn {
                    width: 100%;
                }

                .signing-links {
                    .second-btn {
                        margin-left: unset;
                    }
                    .link {
                        margin-top: 10px;
                    };
                }

                .grouped-btn.delete {
                    position: initial;
                    margin-left: unset;
                }
            }
        }
    }

    table {
        tr {
            border-bottom: 2px solid;
        }
    }

    .from-to-table {
        color: black;

        .from {
            background: rgba(0, 0, 0, 0.05) !important;
        }

        .from:hover {
            background: rgba(0, 0, 0, 0.25) !important;
        }

        .to:hover {
            background: rgba(26, 188, 156, 0.45) !important
        }

        .from,
        .to {
            cursor: pointer;
            align-items: center;
            height: 40px;
            line-height: 40px;
            margin-right: 10px;
        }

        .company-name {
            padding-left: 10px;
            width: 120px;
            padding-right: 10px;
            white-space: nowrap;
            /* Prevent text from wrapping to the next line */
            overflow: hidden;
            /* Hide any text that overflows the container */
            text-overflow: ellipsis;
            /* Add an ellipsis for the truncated text */
        }

        .product-type {
            width: 200px;
            padding-right: 10px;
            white-space: nowrap;
            /* Prevent text from wrapping to the next line */
            overflow: hidden;
            /* Hide any text that overflows the container */
            text-overflow: ellipsis;
            /* Add an ellipsis for the truncated text */
        }

        .investment-policies {
            width: 200px;
            padding-right: 10px;
            white-space: nowrap;
            /* Prevent text from wrapping to the next line */
            overflow: hidden;
            /* Hide any text that overflows the container */
            text-overflow: ellipsis;

            /* Add an ellipsis for the truncated text */
            .item {
                margin-left: 10px;
            }
        }

        .policy-number {
            width: 100px;
            padding-right: 10px;
            white-space: nowrap;
            /* Prevent text from wrapping to the next line */
            overflow: hidden;
            /* Hide any text that overflows the container */
            text-overflow: ellipsis;
            /* Add an ellipsis for the truncated text */
        }

        .commission {
            width: 60px;
            padding: 0 10px 0 0;
            white-space: nowrap;
            /* Prevent text from wrapping to the next line */
            overflow: hidden;
            /* Hide any text that overflows the container */
            text-overflow: ellipsis;
            /* Add an ellipsis for the truncated text */
        }

        span {
            display: inline-block;
        }

    }

    .products-table {
        tr {
            --bs-table-accent-bg: none !important;
        }

        td {
            padding: 0;
        }

        td:first-child {
            padding-left: 15px;
        }

        td:last-child {
            padding-right: 15px;
        }
    }
}