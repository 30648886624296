.inlineBlock {
  display: inline-block;
}

.product {

  .optimize {
    position: absolute;
    right: 15px;
    top: 15px;

    .link {
      cursor: pointer;
      color: #3283f6;
      margin-top: 10px;
      font-size: 16px;
      text-align: right;
      font-weight: normal;
    }
  }
}

.employer-name.active {
  font-weight: 700;
  color: #1ABC9D;
}
.more-options {
  li {
    margin-bottom: 5px;

    .badge {
      margin-left: 5px;
    }
  }
}

.form {
  #status {
    width: auto;
    height: auto;
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
  }
}

.buttons {
  width: 100%;

  .left {
    color: #1abc9c;
    float: left;
  }

  .right {
    float: right;
  }
}

#status {
  width: auto;
  height: auto;
  position: relative;
  left: auto;
  top: auto;
  margin: 0;
}

.inline-block {
  display: inline-block;
  margin-right: 20px;
}

.signing-links {
  margin-bottom: 20px;

  .link {
    margin-top: 10px;

    a {
      color: #6658dd !important;
    }
  }
}

.half {
  width: 46%;
  display: inline-block;
  margin-right: 4%;
  margin-bottom: 20px;
}

.simulation {
  &-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-fields {
      display: flex;
      gap: 25px;
      align-items: end;
    }
  }
  &-calculate {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

}

.hidden {
  display: none;
}

.download {
  line-height: 40px;
  cursor: pointer;
  margin-right: 20px;
}

.un-success {
  background-color: white;
  border: 1px solid #1abc9c;
  color: #1abc9c;
}

.widget-rounded-circle {
  height: 120px;
}

.signing-links {
  display: inline-block;
}

.confirm-modal.modal {
  z-index: 1070;

  .btn-close {
    display: none;
  }
}

.confirm-backdrop.show {
  z-index: 1060;
}


.indented-line {
  white-space: pre;
  /* Preserve white spaces */
  margin-left: 20px;
  /* Adjust the value to control the indentation */
}