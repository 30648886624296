.dashboard-graph {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .rs-dropdown {
        display: inline-block;

        button {
            text-transform: capitalize;
            background: white;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .rs-dropdown-item {
            text-transform: capitalize;
        }
    }

    .chart-wrapper {
        position: relative;
        // margin-top: 40px;
    }

    .date-and-filters {
        display: flex;
        justify-content: flex-start;
        width: 85%;
        min-height: 100px;
        flex-wrap: wrap;
        @media (min-width: 1600px) {
            width: 90%;
            min-height: 95px;
        }
    }

    .rs-picker-daterange {
        align-self: flex-start;
        margin-right: 10px;
        vertical-align: top;
    }

    .clickable-text {
        color: #6658dd;
        font-weight: bold;
        cursor: pointer;
        //display: inline-block;
        // align-self: center;
        position: absolute;
        right: 15px;
        top: 10px;
    }

    .buttons {
        position: absolute;
        right: 0px;
        top: 50px;
        width: 90px;
        font-weight: 600;
        width: 300px;
        text-align: right;

        button {
            display: inline-block;
        }

        .apply-button {
            margin-left: 10px;
        }
    }

    .scrollable-dropdown {
        max-height: 200px;
        /* Adjust as needed */
        overflow-y: auto;
    }

    .dropdown-search-input {
        width: 70%;
        margin: 10px;
        padding: 5px;
    }

    .autocomplete-dropdown {
        .toggle {
            padding: 0;
            transition: none !important;

            &:active {
                border: transparent 1px solid !important;
            }
        }
    }
}
