.comments {
  direction: ltr;

  .header-title {
    text-align: left;
  }

  .comments-list {    
    float: left;
    height: 50vh;
    overflow: auto;
  }

  .select-module {
    direction: rtl;
    text-align: center;
  }

  .filter-section {
    display: flex;
    gap: 10px;
  }

  .ctext-wrap {
    position: relative;
    width: 100%;

    .comment-icon.copy {
      position: absolute;
      right: -30px;
      top: 10px;
      font-size: 16px;
    }

    .comment-icon.delete {
      position: absolute;
      font-size: 20px;
      right: -32px;
      top: 30px;
    }
  }

  .badge {
    padding: 0.5em;
    font-size: 10px;
    float: end;
  }
}