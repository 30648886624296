.my-team-table {
    width: 70%;
    margin: auto;
    margin-top: 15px;
    border: 1px solid lightgray;
    box-shadow: 0px -1px 9px 0px #80808052;
    border-radius: 8px;
    padding: 5px;
    height: calc(98vh - 170px);
    overflow-x: auto;
    overflow-y: auto;
    background-color: #fff;

    .no-team {
        display: flex;
        justify-content: center;
        height: 150px;
        align-items: center;
        font-size: 1.5rem;
    }

    .table-responsive {


        th {
            text-align: center;

            th:before {
                right: 20%
            }

            th:after {
                right: 16%
            }
        }

        tbody {
            
            tr {
               td {
                    padding: 0.85rem 0.85rem 0.85rem 1.5rem;

                    .save-wrapper {
                        display: flex;
                        justify-content: center;

                        button {
                            background-color: #4a81d4;
                            border-color: #4a81d4 ;
                            transition: 0.5s ease;
                        }
                        
                        button:disabled {
                            background-color: #647287;
                            border-color: #647287 ;
                            opacity: 0.07;
                        }

                        button:hover {
                            background-color: #2d5695;
                        }
                    }

                    .inputWrapper .form-select {
                        max-width: 210px;
                        margin: auto;
                    }

                    .inputWrapper .form-select:disabled {
                        max-width: 210px;
                        margin: auto;
                        opacity: 0.3;
                    }

                    .box_1 {
                        display: flex;
                        padding: 5px 8px;
                        margin-right: 1px;
                        justify-content: center;

                        .mode-title {
                            margin-right: 5px;
                            color: var(--bs-body-color);
                        }
                    }
                    
                    input[type="checkbox"].switch_1{
                        font-size: 15px;
                        border: 1px solid lightgrey;
                        appearance: none;
                        width: 3.5em;
                        height: 1.5em;
                        background: #eeeeee;
                        border-radius: 3em;
                        position: relative;
                        cursor: pointer;
                        outline: none;
                        transition: all .2s ease-in-out;
                    }
                    
                    input[type="checkbox"].switch_1:checked{
                        background: #4a81d4;
                    }
                    
                    input[type="checkbox"].switch_1:after{
                        position: absolute;
                        content: "";
                        width: 1.6em;
                        height: 1.6em;
                        border-radius: 50%;
                        background: #fff;
                        box-shadow: 0 0 0.25em #0000004d;
                        left: 0;
                        top: -2px;
                        transition: all .2s ease-in-out;
                    }
                    
                    input[type="checkbox"].switch_1:checked:after{
                        left: calc(100% - 1.5em);
                    }
               } 
            }

            tr:nth-of-type(odd) {
                background-color: #f3f7f9;
            }
        }
        
      
    }
}


.my-team-wrapper ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.my-team-wrapper ::-webkit-scrollbar-thumb {
    background: #a9cafc;
    border-radius: 20px;
}

.my-team-wrapper ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.alert {
    text-align: center;
    padding: 20px;
    font-size: 1.2rem;
    width: 70%;
    margin: auto;
}

