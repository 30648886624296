.timeline-table {
  table {
    .selected {
      background: #ddd;
    }
  }

  .progress-container {
    width: 100%;
    height: 100%;
    position: relative;

    .progress-color {
      height: 100%;
      background: #4d78cd;
    }

    .progress-value {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      color: #aaa;
    }
  }


}