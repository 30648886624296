.files {
    .success {
        color: #00c9a7;
    }

    .warning {
        color: #ffc107;
    }

    .danger {
        color: #de4437;
    }

    a {
        color: #3283f6;
    }

    .view-all-products {
        margin-top: 3px;
        float: right;
        a {
            color: #3283f6;
        }
    }

    .file-gallery {

        .image-popup {
            //padding: 30px;
            display: block;
            width: 100%;
            height: 200px;
            overflow: hidden;



            img {
                cursor: zoom-in;
                width: 100%;
            }
        }



        .gal-box:hover .overlay {
            display: block;
        }


    }
}

.file-list {

    .header-title {
        text-transform: capitalize;
    }

    a {
        color: #3283f6;
    }
}