.employers-wrapper {
        
    /* width */
    ::-webkit-scrollbar {
        width: 15px;
        height: 15px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #348193b5; 
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

    .employers-table {
        max-height: 75vh;
        overflow: auto;
        font-size: 1rem;
        
        .table-responsive {
            overflow-x: unset;
        }
        
        .action-icon {
            cursor: pointer;
            color: #98a6ad;
            font-size: 1.4rem;
            display: inline-block;
            padding: 0;
            margin-right: 14px;
        }
    }
}
