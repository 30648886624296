.inputWrapper {
    font-size: 1rem;
    position: relative;

    .form-control {
        display: block;
        width: 100%;
        padding: 0.45rem 0.9rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6c757d;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.2rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .form-select {
        display: block;
        width: 100%;
        padding: 0.45rem 2.7rem 0.45rem 0.9rem;
        -moz-padding-start: calc(0.9rem - 3px);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6c757d;
        background-color: #fff;
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-position: right 0.9rem center;
        background-size: 14px 10px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
        // margin-bottom: 0.9em;
    }

    .form-label {
        margin-bottom: 1.5rem;
        font-size: 1.1em;
    }
    
    .input-status {
        position: absolute;
        bottom: -27%;
        left: 10px;
        font-size: 0.9em;
        color: #dc3545;
    }

}
