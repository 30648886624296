.tax-return {
    .form-label {
        margin-bottom: 0.5rem;
        font-size: 0.9em;
        color: #333;
    }

    .full-width {
        width: 100%;
    }

    .extra-label-text {
        font-size: 0.9em;
        color: #666;
        margin-right: 0.5rem;
    }

    .tax-form-item {
        margin-bottom: 1.5rem;
    }

    .autocomplete-dropdown {
        .dropdown-menu-right {
            inset: auto !important;
        }
        .dropdown-toggle {
            width: 100%;
            padding: 0;
            .rs-dropdown,
            .rs-dropdown-toggle {
                width: 100%;
                background-color: #fff;
                border: 0.5px solid #ced4da;
                border-radius: 0.25rem;
                text-align: right;
                font-weight: 100;
            }
        }
        .status-dropdown-placeholder {
            display: block;
            width: 100%;
            padding: 0.45rem 2.7rem 0.45rem 0.9rem;
            -moz-padding-start: calc(0.9rem - 3px);
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            color: #6c757d;

            background-repeat: no-repeat;
            background-position: right 0.9rem center;
            background-size: 14px 10px;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            appearance: none;
        }
    }

    .right-side-inner {
        padding-left: 30%;
        padding-right: 5%;
    }
    .col.left-side {
        padding-left: 10%;
        font-size: 1rem;

        input {
            direction: ltr;
            text-align: right;
        }

        .right-padding {
            padding-right: 13%;
        }

        .row.download-files {
            margin-top: 3rem;
            width: 56%;
            padding-right: 14%;
        }
        .meeting-date {
            margin-top: 2rem;
            .meeting-date-value {
                margin-right: 0rem;
            }
        }

        .headline {
            font-size: 0.9em;
            font-weight: bold;
            color: #333;
            margin-bottom: 1rem;
            line-height: 2em;
            padding-right: 0.35rem;
        }

        .col.year {
            flex: 0 0 10%;
            line-height: 2em;
            font-weight: bold;
            color: #333;
        }

        .col.amount {
        }

        .transfer-date {
            width: 47%;
        }
    }
}
