// 
// profile.scss
//

.post-user-comment-box {
    background-color: $gray-100;
    margin: 0 -.75rem;
    padding: 1rem;
    margin-top: 20px;
}


#uploadPreviewTemplate .col-auto {
    position: relative;
}


#uploadPreviewTemplate .col-auto .spinner-screen {
    position: absolute;
    top: 0;
    left: 0;
    z-index:2;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;

    .inner {
        width: 45px;
        height: 45px;
        margin: 0 auto;
        vertical-align: middle;
        position: relative;
        left: 20px;
        top: 8px;
    }
}

.file-type {
    position: relative;
    top: -5px;
    margin-right: 10px
}

.clickable {
    cursor: pointer;
    //background-color:red;
    width: 100%;
    height: 100%;
}

.empty-table-placeholder {

}

.white-title {
    margin-top: 50px;
    padding: 10px 0;
    h4 {
        color: white;
    }
}

.upload-link {
    //width: 80%;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
    a {
        margin-left: 10px;
        color: #2f7787;
    }
}

.pointer {
    cursor: pointer;
}



.edit-profile-icon {
    margin-left: 10px;
}
.edit-profile:hover {
    color: #00acc1;
    cursor: pointer;
}



.profile {

    .image-popup {
        padding: 10px;
        display: block;
        width: 100%;
        height: 200px;
        overflow: hidden;

        img {
            cursor: zoom-in;
            width: 100%;
        }
    }

    .gal-box .overlay {
        display: none;
        width: 100%;
        height: 200px;
    }

    .gal-box:hover .overlay {
        display: block;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);

        .action {
            cursor: pointer;
            position: absolute;
            font-weight: bold;
            font-size: 1em;
            color: white;
            //width: 100%;
            //text-align: center;
            //top: 63px;

            .text {
                position: relative;
                top: -3px;
            }
            i {
                color: white;
                margin-right: 5px;
                font-size: 1.5em;
            }
        }

        .action.delete-file {
            top: 0px;
            right: 9px;
        }



        .action.download-file {
            top: 0px;
            left: 5px;
        }
    }
}

.table-companies,
.table-products {
    img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border: 1px solid #ccc;
    }

    .name-placeholder {
        margin-right: 15px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
        background-color: #00acc1;
        color: white;
        text-align: center;
        display: inline-block;
        font-size: 25px;
    }
}

.highlighted {
    background-color: #1abc9c !important;
    color: white;

    i {
        font-size: 30px;
    }
}

.bg-soft-highlight {
    background-color: rgba(255, 255, 255, 0.1)
}

.border.border-highlight {
    border: 2px solid #ffffff !important;
}