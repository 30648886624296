.deals-modal {
    .alert {
        width: 50%;
        margin: auto;
        border: none;
        font-size: 20px;
        text-align: center;

        button {
            font-size: 20px;
            margin: 10px auto;
        }
    }
    .modal-dialog {
        max-width: 85%;

        .modal-body {
            overflow: auto;
        }

        @media (max-width: 992px) {
            max-width: 95%;
        }

        form.deal-form {
            margin: 45px auto 55px auto;
            position: relative;
            // border-bottom: 1px solid lightgray;

            #preloader {
                height: 100px;
                position: absolute;
                display: flex;
                top: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #4e6382bf;
                z-index: 9999;
                justify-content: center;
                align-items: center;
            }

            .input-wrapper {
                width: 100%;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: flex-end;

                .inputWrapper .form-select.new-item {
                    border-left: 15px solid #ffc107;
                }

                @media (max-width: 1100px) {
                    justify-content: space-between;
                }
                
                .input-field.number-field {
                    width: 12%;
                    min-width: 160px;

                    input {
                        line-height: 30px;
                    }
                }
                
                .input-field.select-field {
                    width: 26%;
                    min-width: 170px;
                    select {
                        line-height: 30px;
                    }
                }
                
                .input-field.select-field.product-type {
                    width: 22%;
                }

                button {
                    font-size: 20px;
                }

                button.save-new {
                    background-color: #ffc107;
                    border-color: #ffc107;
                    box-shadow: 3px 2px 5px 0px #1e1a1a73;
                }

                button.delete-new {
                    background-color: #6c757d96;
                    border-color: transparent;
                }
            }

            .deal-status-wrapper {
                margin-top: 15px;
                
                div {
                    margin-left: 6%;
                    font-size: 20px;
                    font-weight: 700;
                }
               
               .warning {
                    color: #ffc107;
               }

               .success {
                color: #1abc9c;;
               }
            }
        }

        .text-end {
            font-size: 20px;
            margin: 100px auto 0 auto;
            
            button {
                font-size: 20px;
            }
            
            .btn-success {
                margin: 0 15px;
            }
        }
    }
}


