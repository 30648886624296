.user-profile-box {

    .pension-status-wrapper {
        margin: 20px auto;

        .select-field {
            width: 70%;
            margin: auto;
        }

        .pension-status {
            width: 100%;
            text-align: center;
            font-size: 1rem;
        }
    }

    .reason-select {
        width: 200px;
        padding: 3px;
        /* Adjust the width as needed */
        border: 1px solid #ccc;
        border-radius: 5px;
        /* Adding rounded corners */
    }

    .edit {
        cursor: pointer;
        color: #6658dd;
        margin-left: 30px;
    }

    .lead-qualification {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .qualification-label {
        margin-right: 20px;
        margin-left: 10px;

    }

    @media (max-width: 1530px) and (min-width: 1471px) {
        .qualification-label {
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    .radio-text {
        margin-left: 8px;
    }

    .reason-select {
        margin-left: 10px;
    }

    .other-reason-container {
        display: flex;
        align-items: center;
    }

    .other-reason-input {
        margin-right: 3px;
        margin-left: 58px;
        width: 250px;
        padding: 3px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .submit-button {
        background-color: #4d78cd;
        color: white;
        border: none;
        padding: 3px 1px;
        border-radius: 5px;
        cursor: pointer;
        min-width: 40px;
        
    }

    .submit-button .MuiButton-startIcon {
        transform: translateX(35%);
      }



}