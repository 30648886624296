//
// dropzone.scss
//

.dropzone {
    border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    cursor: pointer;
    height: 200px;
    padding: 0px;
    display: inline-block;
    width: 100%;
    box-shadow: $components-shadow-sm;
    position: relative;

    .needsclick {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .dz-message {
        text-align: center;
        margin-top: 61px;
        width: 100%;
        outline: none;
        display: inline-block;
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}
