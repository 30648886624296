.center-vertically {
  vertical-align: middle;
  position: relative;
  margin-top: 29px;
}

.search-wrapper {
  .search-bar {
    .form-control {
      padding-left: 20px;
      padding-right: 40px;
      border-radius: 30px;
    }

    .apply-search {
      cursor: pointer;
      color: #43a8bf;
      font-size: 20px;
      line-height: calc(1.5em + 0.9rem + 2px);
      right: 13px;
      left: unset;
      top: -2px;
    }
  }
}

.react-select > div:not(.react-select__control) {
  border: none !important;
}

.react-select.react-select-container {
  margin-bottom: 0px;
}

.align-right {
  text-align: end;
}