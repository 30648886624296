.tax-return-complete-form {
    .tabs-container {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;

        .tab {
            cursor: pointer;
            font-weight: normal;
            border-radius: 0.15rem;
            width: 10%;
            font-weight: bold;
            border: none;

            &.active-tab {
                background-color: rgb(83, 116, 201);
                color: #fff;
            }
        }
    }

    .form-container {
        padding: 20px 50px;

        .form-label {
            margin-bottom: 5px;
            margin-top: 12px;
        }

        h4 {
            margin-bottom: -10px;
            text-align: center;
            color: rgb(83, 116, 201);
        }

        .general-data-form,
        .per-year-data-form {
            display: flex;
            gap: 100px;

            .client-spouse-data {
                width: 400px;
                display: flex;
                flex-direction: column;
                gap: 35px;

                .data-form {
                    display: flex;
                    flex-direction: column;
                    gap: 35px;
                }
            }
        }
    }

    .buttons {
        padding: 20px 50px;
        display: flex;
        justify-content: space-between;

        .submit-button {
            min-width: 120px;
            border-radius: 0.15rem;

            &.active {
                background-color: rgb(83, 116, 201);
                border: none;
                color: #fff;

                &:hover,
                &:focus {
                    background-color: rgb(40, 84, 197);
                    border: none;
                    color: #fff;
                }
            }

        }

    }

}