.cubes-modal {
    .modal-dialog {
        max-width: 1000px;
    }
    table {
        th, td {
            border: 1px solid #ddd;
            padding: 7px;
            position: relative;
        }

        td.actions {
            font-size: 20px;

            i {
                cursor: pointer;
                margin-right: 2px;
            }

            i:nth-of-type(2):hover {
                color: var(--bs-green)
            }

            i:nth-of-type(1):hover {
                color: var(--bs-red)
            }            
        }

        .dropdown-item {
            direction: rtl;
            text-align: right;
        }

        .select-action a {
            font-size: 15px;
        }
        

        .no-products {
            text-align: center;
            padding: 50px;
            font-size: 30px;
        }

        .dropdown {
            display: inline-block;
            .form-label {
                display: none;
            }

            select {
                // padding-right: 13px;
                border: none;
                border-bottom: 1px solid #fff;
                border-radius: 0;
                width: 80px;
            }

            select:hover {
                border-bottom: 1px solid #bbb;
            }
        }

        input {
            width: 50px;
            border: none;
            border-bottom: 1px solid #bbb;
            height: 35px;
            padding-right: 21px;
            width: 70px;
        }

        input {
            
        }

        input[name="relevantSalary"] {
            width: 100px;
            height: 35px;
            padding-left: 20px;
        }

        input[name="workDisability"] {        
            height: 35px;
            padding-right: 20px;
            width: 60px;
        }

        .percent {
            position: absolute;
            right: 5px;
            bottom: 15px;
        }

        .shekel {
            position: absolute;
            left: 5px;
            bottom: 17px;
        }
        
    }
}