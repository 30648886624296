
.file-gallery {

  .image-popup {
    //padding: 30px;
    display: block;
    width: 100%;
    height: 200px;
    overflow: hidden;

    .inner {
      position: relative;
      width: 100%;
      height: 100%;

      .file-placeholder {
        width: 100%;
        //height: 160px;
        /* margin: 20px 10%; */
        background-color: #ccc;
        color: #0b2e13;
        font-size: x-large;
      }

      .overlay-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .gal-box .overlay {
          display: block;
          width: 100%;
          height: 200px;

          .date {
            position: absolute;
            color: black;
          }

          .action {
            cursor: pointer;
            position: absolute;
            font-weight: bold;
            font-size: 1em;
            color: white;
            //width: 100%;
            //text-align: center;
            //top: 63px;

            .text {
              position: relative;
              top: -3px;
            }

            i {
              color: white;
              margin-right: 5px;
              font-size: 1.5em;
            }
          }

          .action.delete-file {
            //top: 0px;
            //right: 9px;
          }


          .action.download-file {
            //top: 0px;
            //left: 5px;
          }
        }
      }
    }

    img {
      cursor: zoom-in;
      width: 100%;
    }
  }

  .file-name {
    background: rgba(0,0,0,.5);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    display: inline-block;
    display: block;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 10;
  }
  .gal-box .gall-info {
    padding: 0 10px 10px 10px;
  }

  .gal-box .overlay {
    display: none;
    width: 100%;
    height: 160px;
  }

  .gal-box .inner .date {
    position: absolute;
    right: 5px;
    top: 3px;
    font-size: 12px;
    color: #111;
  }

  .gal-box:hover .overlay {
    display: block;
  }

  .overlay {
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);

    .action {
      cursor: pointer;
      display: inline-block;
      //position: absolute;
      font-weight: bold;
      font-size: 0.9em;
      color: white;
      //width: 100%;
      //text-align: center;
      //top: 63px;

      .text {
        position: relative;
        top: -3px;
      }

      i {
        color: white;
        margin-right: 5px;
        font-size: 1.5em;
      }
    }

    .action.delete-file {
      top: 30px;
      left: 5px;
    }


    .action.download-file {
      top: 0px;
      left: 5px;
    }
  }
}
