.modal-fullscreen {
    .modal-content {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
    }

    .modal-header,
    .modal-body {
        flex-shrink: 0;
    }

    .modal-body {
        display: flex;
        flex: 1;
        overflow: hidden;
        position: relative;
    }

    .image-container {
        flex: 4;
        padding: 0px 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .right-side-container {
        min-width: 300px;
    }

    .form-buttons {
        display: flex;
        justify-content: space-between;
        margin-right: 30px;
        margin-top: 10px;
        padding: 10px;
        background-color: rgba(191, 191, 191, 0.179);
        border-radius: 5px;

        button {
            min-width: 100px;
        }

        @media (max-width: 1550px) {
            margin-top: 5px;
        }
    }

    .form-container {
        flex: 1;
        margin-right: 10px;
        box-sizing: border-box;
        overflow-y: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 92%;

        @media (max-width: 1550px) {
            height: 91%;
        }

        .label-form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 30px;
        }
    }

    .right-side-of-header {
        display: flex;
        gap: 20px;
        flex-direction: row-reverse;
        align-items: center;
        position: absolute;
        right: 60px;
        // top: 0;
    }

    .gallery-modal-header {
        position: relative;
        background-color: rgba(216, 216, 216, 0.179);
    }

    .approved-by {
        position: relative;
        width: 160px;
        height: 100px;

        p {
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            margin: 0;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }


    .nav-button {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        z-index: 1050;

        &:focus {
            outline: none;
        }
    }

    .prev-button {
        left: 0;
    }

    .next-button {
        right: 0;
    }
}