// 
// product.scss
//

.right {
    float: right;
}

button.optimize {
    position: absolute;
    top: 13px;
    right: 13px;
    z-index: 10;
}