.auto-complete-select {
    .toggle {
        background: white !important;
        color: #6c757d !important;
        border: 1px solid lightgrey !important;
        width: 100%;
        text-align: left;
        position: relative;
        border-radius: 0.25rem;
        cursor: default !important;
        transition: none !important;

        &:hover,
        &:focus,
        &:active {
            background: white;
            color: #6c757d;
            outline: none;
        }

        .arrow {
            position: absolute;
            right: 11.5px;
            top: 5px;
            color: rgba(46, 51, 56, 0.879);
            font-size: 19px;
        }
    }

    .menu {
        direction: rtl;
        width: 100%;
        min-width: 200px;
        background-color: rgb(223, 223, 223);
        color: black;
        border: 1px solid lightgrey;
        box-shadow: 0 0 10px 1px #80808073;
        max-height: 70vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .item {

            text-align: right;
            cursor: default;
            font-size: 14px;
            color: black;
            border-radius: 0.25rem;
            height: 26px;

            &:hover {
                background-color: rgb(62, 149, 255);
                color: #ffff;
            }
        }

        input {
            width: 100%;
            border: 1px solid lightgrey;
            border-radius: 0.25rem;
            padding: 0 5px;
            height: 26px;
            margin-bottom: 5px;

            &:focus {
                outline: none;
            }
        }
    }
}